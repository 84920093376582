import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
// We only import ReactDOM for @axe-core/react for development mode
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import App from './App';
import ExceptionHandler from './views/ExceptionHandler/ExceptionHandler';
import './index.scss';
import { logMessage } from "./utils/Logger";
import { logErrCode, logErrDetails, logLabel } from "./constants/constants";
// import {worker} from '../msw/worker';
import { createPreloadImageTags } from './utils/Utils';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
export function AppContainer() {
    return (_jsx(ErrorBoundary, { fallback: _jsx(ExceptionHandler, {}), onError: (error) => logMessage(logLabel.error, logErrCode.reactErr, logErrDetails.reactErr + error, true), children: _jsx(App, {}) }));
}
if (process.env.NODE_ENV !== 'production') {
    // Warning! @axe-core/react may not support all features of React 18
    // See https://github.com/dequelabs/axe-core-npm/issues/500
    import('@axe-core/react').then((axe) => {
        // Results will show in the DevTools console
        axe.default(React, ReactDOM, 1000);
        root.render(_jsx(AppContainer, {}));
    });
}
else {
    root.render(_jsx(AppContainer, {}));
}
//lets preload-the images for caching
createPreloadImageTags();
